<template>
  <div class="result">
    <div id="section-content" class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"
          ><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link
        >
      </div>
      <div
        class="p-2 w-100 bg-light main-content align-self-stretch"
        style="min-height: calc((100vh - 140px) - 50px)"
      >
        <div
          class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
        >
          <div
            class="lotto-title d-flex flex-row justify-content-between align-items-center w-100"
          >
            <h4><i class="fas fa-award"></i> ผลรางวัล</h4>
          </div>
        </div>
        <div class="bg-white p-2 rounded shadow-sm w-100 mb-1">
          <label><i class="fas fa-calendar-alt"></i> วันที่เริ่ม</label>
          <div class="input-group date" id="datetimepicker4" data-target-input="nearest">
             <b-form-datepicker
                id="datepicker-dateformat2"
                :date-format-options="{ day: 'numeric',month: 'numeric',  year: 'numeric' }"
                locale="th"
                class="form-control datetimepicker-input mb-0"
                v-model="selectDate"
                placeholder="DD-MM-YYYY"
              ></b-form-datepicker>
           <!-- <input type="text" class="form-control datetimepicker-input mb-0" data-target="#datetimepicker4" id="dt" data-container="body" data-toggle="datetimepicker"> -->
              <div class="input-group-append" data-target="#datetimepicker4" data-toggle="datetimepicker">
                  <button class="btn btn-outline-secondary"><i class="fas fa-calendar my-0"></i></button>
              </div>
          </div>
        </div>
        <b-overlay id="overlay-background" :show="showOverlay" rounded="lg"
          variant="dark"
          opacity="0.35"
          blur="2px">
        <div
          class="bgwhitealpha text-secondary shadow-sm rounded p-2 xtarget col-lotto mb-5"
        >
          <section id="contentbox">
            <app-hot-hit v-bind:dataList="getLottoResultAllList[3]" v-if="getLottoResultAllList[3].length > 0"/>
            <app-country-lotto v-bind:info="getLottoResultAllList[0].filter(v => v.title == 'หวยรัฐบาล')[0]" v-if="getLottoResultAllList[0].filter(v => v.title == 'หวยรัฐบาล').length > 0"/>
              <app-thai-lotto
              v-bind:toDay="toDay(selectDate)"
              v-bind:dataList="getLottoResultAllList[0].filter(v => v.title != 'หวยรัฐบาล')"
              v-if="getLottoResultAllList[0].filter(v => v.title != 'หวยรัฐบาล').length > 0"
              />
            <app-inter-lotto
              v-bind:toDay="toDay(selectDate)"
              v-bind:isMobile="$isMobile(selectDate)"
              v-bind:dataList="getLottoResultAllList[2]"
              v-if="getLottoResultAllList[2].length > 0"
            />
            <template v-if="getLottoResultAllList[1][0] != null">
               <app-yeekee-vip :toDay="toDay(selectDate)" :dataList="getLottoResultAllList[1][0]" :u8Type="0" :isVip="false" v-if="getLottoResultAllList[1][0].length > 0"/>
            </template>
            <template v-if="getLottoResultAllList[1][1] != null">
              <app-yeekee-vip :toDay="toDay(selectDate)" :dataList="getLottoResultAllList[1][1]" :u8Type="1" :isVip="true" v-if="getLottoResultAllList[1][1].length > 0"/>
            </template>
            <template v-if="getLottoResultAllList[1][2] != null">
               <app-yeekee-vip :toDay="toDay(selectDate)" :dataList="getLottoResultAllList[1][2]" :u8Type="2" :isVip="true" v-if="getLottoResultAllList[1][2].length > 0"/>
            </template>
          </section>
        </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>
<script>
import HotHit from '@/components/HotHit'
import CountryLotto from '@/components/CountryLotto'
import InterLotto from '@/components/InterLotto'
import ThaiLotto from '@/components/ThaiLotto'
import YeekeeVip from '@/components/YeekeeVip'
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    'app-hot-hit': HotHit,
    'app-country-lotto': CountryLotto,
    'app-inter-lotto': InterLotto,
    'app-yeekee-vip': YeekeeVip,
    'app-thai-lotto': ThaiLotto
  },
  async created () {
    try {
      this.showOverlay = true
      this.setLoading(true)
      await Promise.all([
        this.getLottoResultAll(this.selectDate)
      ])
      this.setLoading(false)
      this.showOverlay = false
    } catch (error) {
      this.setLoading(false)
      this.showOverlay = false
    }
  },
  methods: {
    ...mapActions(['setLoading', 'getLottoResultAll']),
    toDay: (data) => {
      if (data === '') {
        return format(new Date(), 'dd MMMM yyyy')
      } else {
        return format(new Date(data), 'dd MMMM yyyy')
      }
    }
  },
  computed: {
    ...mapGetters(['getLottoResultAllList'])
  },
  data: () => {
    return {
      selectDate: '',
      showOverlay: true
    }
  },
  watch: {
    selectDate: function (val) {
      this.showOverlay = true
      this.getLottoResultAll(val)
    },
    getLottoResultAllList: function (val) {
      if (this.showOverlay === true) {
        this.showOverlay = false
      }
    }
  }
}
</script>

<style>
</style>
